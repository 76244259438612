<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import Gen from '@helper/Gen';
	import swal from 'sweetalert';
	// import draggable from 'vuedraggable'

	export default {
		name: "BoCategories",
		extends: Base,
		// components: {
		//    draggable,
		// },
		data() {
			return {
				Name: "BoCategories",
				row2: {},
				parentMenu: {},
				row: {mpc_parent_id:1},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			},
		},
		methods: {
			endDrag() {
				BOGen.apirest('/' + this.Name, {
					data: this.data.data,
					type: 'sort'
				}, (err, resp) => {
					console.log(resp)
				}, "POST");
			},
			changeStatusId(k,isProduct, active="Active", inactive="Inactive"){
				var v = k
				var text = (v.mpc_is_active=="Y"?inactive:active)
				var text2 = isProduct == 'Y' ? 'this brand already have product' : ''
				swal({
					title: "Are you sure to "+text+"?",
					text:text2,
					icon: "warning",
					buttons: ["No, Cancel!", "Yes, "+text+"!"],
					// dangerMode: true,
				}).then((ok) => {
					if (ok) {
						v.mpc_is_active = v.mpc_is_active == "Y" ? "N" : "Y"
						BOGen.apirest("/"+this.Name, {type:"status",id:v.mpc_id,status:v.mpc_is_active},(err,resp)=>{
							if(err) return swal(err.resp.message,"","warning")
							swal(resp.message,"","success")
							this.refreshData()
						}, "POST")
					}
				});
			},
			submitSeo() {
				this.row2.type = 'updateSeo'
				BOGen.apirest('/' + this.Name, this.row2, (err, resp) => {
					if (resp.error) return Gen.info(resp.message, 'danger', 3000, '.seo-modal')
					if (resp.success) {
						Gen.info(resp.message, 'success', 2000, '.seo-modal')
						this.refreshData()
						setTimeout(() => {
							this.initPopover()
						}, 500)
						setTimeout(() => {
							$('#add_city').modal('hide')
						}, 2100)

					}
				}, "POST");
			},
			deleteItemId(id,row){
				var v = id
				swal({
					title: "Are you sure to delete "+this.ObjectName+"?",
					text:"",
					icon: "warning",
					buttons:{
					delete:{
						text:"Delete",
						value:"delete",
						className:'swal-button--danger',
					},
					deleteItemPermanen:{
						text:'Delete Permanen!',
						value:"deleteItemPermanen",
						className:'swal-button--danger',
					},
					cencelIt:{
						text:'Cancel',
						value:"cencel",
						className:'swal-button--default',
					},
					}
				}).then((value) => {
					switch (value) {

					case "delete":
						v.status = "R"
						BOGen.apirest("/"+this.Name, {type:"delete",id:v.mpc_id},(err, resp)=>{
						swal(resp.message, "", "success");
						this.refreshData()
						// $('#'+row).remove()
						}, "POST")
						break;

					case 'deleteItemPermanen':
						v.status = "V"
						BOGen.apirest("/"+this.Name, {type:"deleteItemPermanen",id:v.mpc_id},(err, resp)=>{
						swal(resp.message, "", "success");
						this.refreshData()
						// $('#'+row).remove()
						}, "POST")
						break;

					case 'cencelIt':
						return;
						break;

					default:
						break;
					}
				});
			},
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row justify-content-between">
								<div class="col-md-5">
									<h5 class="card-title">{{ObjectName}} List</h5>
								</div>
								<div class="col-md-6 col-lg-4">
									<div class="form-row">
										<div class="col-9">
											<div class="form-group mb-0">
												<div class="input-group">
													<input type="text" v-model="filter.search" class="form-control" placeholder="Search...">
													<div class="input-group-append">
														<button class="btn btn-info" type="button" @click="search()"><i class="fas fa-search"></i></button>
													</div>
												</div>
											</div>
										</div>
										<div class="col-3">
											<router-link :to="{name:Name}" class="btn btn-warning btn-block">Reset</router-link>
										</div>
									</div>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="mpc_name_en"></SortField>
									</th>
									<th>Update At</th>
									<th>Status</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="(v,k) in data.data">
									<tr :key="k">
										<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
										<td>{{v.mpc_name_en}}</td>
										<td>{{(v.mpc_update_at).dates('format2')}}</td>
										<td>
											<StatusLabel :status="v.status"></StatusLabel>
										</td>
										<td class="btn-action">
											<router-link class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'"><i
													class="ti-marker-alt"></i></router-link>
											<a href="javascript:;" class="icon_action" @click="changeStatusId(v,v.isProduct)"
												v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
											<a v-if="v.mpc_id > 4" href="javascript:;" class="icon_action" @click="deleteItemId(v,$event)"
												v-tooltip="'Remove'"><i class="ti-trash"></i></a>
										</td>
									</tr>
									<template v-if="v.items.length">
										<tr v-for="(v2,k2) in v.items" :key="k2">
											<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}.{{(k2+1)}}</td>
											<td>-- {{v2.mpc_name_en}}</td>
											<td>{{(v2.mpc_update_at).dates('format2')}}</td>
											<td>
												<StatusLabel :status="v2.mpc_is_active"></StatusLabel>
											</td>
											<td class="btn-action">
												<router-link class="icon_action" :to="{name:Name,params:{id:v2.mpc_id}}" v-tooltip="'Edit'"><i
														class="ti-marker-alt"></i></router-link>
												<a href="javascript:;" class="icon_action" @click="changeStatusId(v2,v2.isProduct)"
													v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
												<a href="javascript:;" class="icon_action" @click="deleteItemId(v2,$event)"
													v-tooltip="'Remove'"><i class="ti-trash"></i></a>
											</td>
										</tr>
									</template>
								</template>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="panel-body">
						<div class="pull-right">
							<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-md-6">
							<BoField name="mpc_parent_id" mandatory v-if="row.mpc_parent_id!=0">
								<select2 name="mpc_parent_id" v-bind="validation('mpc_parent_id')" :options="parentMenu"
									:object="['mpc_id','mpc_name_en']" v-model="row.mpc_parent_id">
									<option value="0">-- Select Category --</option>
								</select2>
								<label :error="'mpc_parent_id'" :label="'Select Category'"></label>
							</BoField>
							<BoField name="mpc_name_en" v-model="row.mpc_name_en"></BoField>
							<BoField name="mpc_name_id" v-model="row.mpc_name_id"></BoField>
							<BoField name="mpc_is_active">
								<div class="row">
									<radio name="mpc_is_active" v-model="row.mpc_is_active" option="Y" :attr="validation('mpc_is_active')">Active
									</radio>
									<radio name="mpc_is_active" v-model="row.mpc_is_active" option="N">Inactive</radio>
								</div>
							</BoField>
						</div>
						<div class="col-md-4">
							<BoField name="mpc_image" v-if="row.mpc_type == 'C'">
								<Uploader name="mpc_image" :param="{thumbnail:true}" type="categories" uploadType="cropping"
									v-model="row.mpc_image"></Uploader>
							</BoField>
						</div>
						<div class="col-sm-6" v-if="row.mpc_type=='C'">
							<BoField name="mpc_meta_title_en" minlegth="3" required="required" maxlegth="100" mandatory
								v-model="row.mpc_meta_title_en"></BoField>
							<BoField name="mpc_meta_keyword_en" mandatory>
								<TagsInput :placeholder="'Add keyword'" name="mpc_meta_keyword_en" v-model="row.mpc_meta_keyword_en"
									:attr="validation('mpc_meta_keyword_en')"></TagsInput>
							</BoField>
							<BoField mandatory name="mpc_meta_desc_en">
								<textarea rows="4" name="mpc_meta_desc_en" required="required" title="Input meta description."
									minlegth="3" maxlegth="300" v-model="row.mpc_meta_desc_en" v-bind="validation('mpc_meta_desc_en')"
									class="form-control"></textarea>
							</BoField>
						</div>
						<div class="col-sm-6" v-if="row.mpc_type=='C'">
							<BoField name="mpc_meta_title_id" v-model="row.mpc_meta_title_id"></BoField>
							<BoField name="mpc_meta_keyword_id">
								<TagsInput :placeholder="'Add keyword'" name="mpc_meta_keyword_id" v-model="row.mpc_meta_keyword_id"
									:attr="validation('mpc_meta_keyword_id')"></TagsInput>
							</BoField>
							<BoField name="mpc_meta_desc_id">
								<textarea rows="4" name="mpc_meta_desc_id" v-model="row.mpc_meta_desc_id"
									v-bind="validation('mpc_meta_desc_id')" class="form-control"></textarea>
							</BoField>
						</div>
						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-rounded btn-info btn-outline btn-1e btn-loading">Save Category</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>